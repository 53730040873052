import React from "react";
import { useEffect, useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useExpanded,
} from "react-table";
import Pagination from "./Pagination";
import "./Table.css";
import { ColumnFilter } from "./ColumnFilter";

export default function Table({ columns, data, sortByDefault, hideColumns }) {
  const [filterState, setFilterState] = useState({});
  const [pageState, setPageState] = useState({});

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageSize, pageIndex, filters, expanded },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: ColumnFilter },

      initialState: {
        ...pageState,
        sortBy: sortByDefault ? sortByDefault : [],
        hiddenColumns: hideColumns ? hideColumns : [],
        filters: Object.entries(filterState).map(([id, value]) => ({
          id,
          value,
        })),
      },
      autoResetFilters: false,
      autoResetPage: false,
      // getRowCanExpand: () => true,
      // // getCoreRowModel: getCoreRowModel(),
      // getExpandedRowModel: getExpandedRowModel(),
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    setFilterState(
      Object.fromEntries(filters.map(({ id, value }) => [id, value]))
    );
  }, [filters]);

  useEffect(() => {
    setPageState({ pageIndex, pageSize });
  }, [pageIndex, pageSize]);

  const renderSubComponent = ({ row }) => {
    const infoTexts = [
      { label: "Tipo de registro:", value: row.original.tipoRegistro },
      {
        label: "Nombre incio del servicio:",
        value: row.original.conductorInicio,
      },
      {
        label: "Nombre termino del servicio:",
        value: row.original.conductorFinal,
      },
      {
        label: "Nombre responsable de la planilla:",
        value: row.original.responsablePlanilla,
      },
    ];

    return (
      <div className="cuenta-cobro-info">
        {infoTexts.map((text, index) => (
          <React.Fragment key={index}>
            <div className="cuenta-cobro-info-text">
              <b>{text.label}</b>
            </div>
            <div className="cuenta-cobro-info-text">{text.value}</div>
          </React.Fragment>
        ))}
      </div>
    );
  };
  return (
    <div className="main-table">
      <table {...getTableProps()} className="w-full divide-y divide-gray-200">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width },
                  })}
                >
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <React.Fragment {...row.getRowProps()}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                        },
                      })}
                    >
                      {" "}
                      {cell.render("Cell")}{" "}
                    </td>
                  ))}
                </tr>
                {row.isExpanded && (
                  <tr>
                    {/* 2nd row is a custom 1 cell row */}
                    <td
                      colSpan={columns.length}
                      style={{ alignContent: "center" }}
                    >
                      {renderSubComponent({ row })}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <Pagination
        gotoPage={gotoPage}
        length={data.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </div>
  );
}
