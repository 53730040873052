import React, { useEffect, useState } from "react";
import axios from "../../utils/axios";
import "./RutasPage.css";
import Title from "../../components/Title/Title";
import Table from "../../components/Table/Table";
import ModalRutas from "../../components/Modal/ModalRutas";
import ModalZonas from "../../components/Modal/ModalZona";
import ModalCiudades from "../../components/Modal/ModalCiudades";
import IconButtonTable from "../../components/IconButtonTable/IconButtonTable";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { errorHandler, openSuccessToast } from "../../utils/errorToast";
import ButtonHome from "../../components/ButtonHome/ButtonHome";
import Loading from "../../components/Loading/Loading";

export default function RutasPage() {
  const [rutas, setRutas] = useState([]);
  const [rutaToEdit, seRutaToEdit] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalZonasOpen, setIsModalZonasOpen] = useState(false);
  const [isModalCiudadesOpen, setIsModalCiudadesOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.roles.includes("ROLE_ADMIN");
  const columns = [
    {
      Header: "Nombre ruta",
      accessor: "nombreRuta",
    },
    {
      Header: "Ciudad origen",
      accessor: "ciudadOrigen.nombreCiudad",
      Cell: ({ row }) => (
        <>
          <div class="hover-text">
            {row.original.ciudadOrigen.nombreCiudad}
            <span class="tooltip-text top">
              {row.original.ciudadOrigen.nombreCiudad}
            </span>
          </div>
        </>
      ),
    },
    {
      Header: "Ciudad destino",
      accessor: "ciudadDestino.nombreCiudad",
      Cell: ({ row }) => (
        <>
          <div class="hover-text">
            {row.original.ciudadDestino.nombreCiudad}
            <span class="tooltip-text top">
              {row.original.ciudadDestino.nombreCiudad}
            </span>
          </div>
        </>
      ),
    },
    {
      Header: "Tipo de ruta",
      accessor: "tipoRuta",
    },

    {
      Header: "Precio",
      accessor: "precio",
      filterable: true,
      Cell: ({ row }) => (
        <>
          $
          {row.original?.precio.toLocaleString(navigator.language, {
            minimumFractionDigits: 0,
          })}
        </>
      ),
    },
    {
      Header: "Zonas",
      accessor: "zonas",
    },
    {
      Header: "EPS",
      accessor: "eps",
    },
    {
      Header: "Acciones",
      Cell: ({ row }) => (
        <>
          <IconButtonTable
            title="Ver ruta"
            icon={<FaEdit size={25} color={isAdmin ? "#ff8144" : "#91918e"} />}
            action={() => handleEdit(row.original)}
            disabled={!isAdmin}
          />
          <IconButtonTable
            title="Borrar ruta"
            icon={
              <FaTrashAlt size={25} color={isAdmin ? "#ff8144" : "#91918e"} />
            }
            action={() => deleteRuta(row.original)}
            disabled={!isAdmin}
          />
        </>
      ),
    },
  ];
  useEffect(() => {
    getAllRutas();
  }, [setRutas]);
  const getAllRutas = () => {
    setIsLoading(true);
    axios
      .get("getAllRutas")
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
          setRutas(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(true);
        errorHandler(error, "Error al cargar rutas");
      });
  };
  const handleEdit = (data) => {
    seRutaToEdit(data);
    setIsModalOpen(true);
  };
  const deleteRuta = (data) => {
    setIsLoading(true);
    axios
      .post("deleteRuta", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
          openSuccessToast("Se ha borrado la ruta");
          getAllRutas();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        let message = error.response?.data?.message
          ? error.response?.data?.message
          : "Error al borrar la ruta";
        errorHandler(error, message);
      });
  };
  const uploadRutas = (event) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    axios
      .post("uploadRutas", formData)
      .then(() => {
        setIsLoading(false);
        getAllRutas();
        openSuccessToast("Se han cargado las rutas");
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error);
      });
  };
  const downloadRutas = () => {
    setIsLoading(true);
    const actualRows = rutas.map((row) => row.original);
    axios
      .post("downloadRutas", rutas)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "rutas.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error, "Error al descargar las rutas");
      });
  };
  const updateRutas = (event) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    axios
      .post("updateRutas", formData)
      .then(() => {
        setIsLoading(false);
        getAllRutas();
        openSuccessToast("Se han actualizado las rutas");
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error);
      });
  };
  return (
    <div className="rutas-container-page">
      <Title text="Rutas" />
      <ButtonHome />
      <div className="container-buttons-rutas">
        {isAdmin && (
          <button
            type="submit"
            className="btn-add-zona"
            onClick={() => {
              seRutaToEdit(null);
              setIsModalOpen(true);
            }}
          >
            Agregar ruta
          </button>
        )}
        {isAdmin && (
          <div className="btn-add-zona">
            <label for="upload-rutas" className="btn-load-cuentas">
              Cargar rutas
            </label>
            <input
              type="file"
              id="upload-rutas"
              onChange={(e) => uploadRutas(e)}
            />
          </div>
        )}
        {isAdmin && (
          <button
            type="submit"
            className="btn-load-cuentas"
            onClick={downloadRutas}
          >
            Descargar rutas
          </button>
        )}
        {isAdmin && (
          <div className="btn-add-zona">
            <label
              for="update-rutas"
              style={{ cursor: "pointer", padding: "1rem 4rem" }}
            >
              Actualizar precios
            </label>
            <input
              type="file"
              id="update-rutas"
              onChange={(e) => updateRutas(e)}
            />
          </div>
        )}
        <button
          type="submit"
          className="btn-add-zona"
          onClick={() => setIsModalZonasOpen(true)}
        >
          Ver zonas
        </button>
        <button
          type="submit"
          className="btn-add-zona"
          onClick={() => setIsModalCiudadesOpen(true)}
        >
          Ver ciudades
        </button>
      </div>

      <section className="container-rutas-table">
        <Table columns={columns} data={rutas} />
      </section>
      {isModalOpen && (
        <ModalRutas
          setIsOpen={setIsModalOpen}
          reloadRutas={getAllRutas}
          ruta={rutaToEdit}
        />
      )}
      {isModalZonasOpen && <ModalZonas setIsOpen={setIsModalZonasOpen} />}
      {isModalCiudadesOpen && (
        <ModalCiudades setIsOpen={setIsModalCiudadesOpen} />
      )}
      {isLoading && <Loading />}
    </div>
  );
}
