import React, { useState } from "react";
import "./FormRegistroPage.css";
import axios from "../../utils/axios";
import Title from "../../components/Title/Title";
import { errorHandler, openSuccessToast } from "../../utils/errorToast";
import ButtonHome from "../../components/ButtonHome/ButtonHome";

export default function FormRegistroPage() {
  const [values, setValues] = useState({
    nombreUsuario: "",
    tipoDocumento: "",
    numeroDocumento: "",
    rol: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const resetValues = () => {
    setValues({
      nombreUsuario: "",
      tipoDocumento: "",
      numeroDocumento: "",
      rol: "",
    });
  };
  const sendData = () => {
    let data = {
      cedula: values.numeroDocumento,
      contrasena: values.numeroDocumento,
      rol: values.rol,
      empleadoDto: {
        nombreEmpleado: values.nombreUsuario,
        tipoDocumento: values.tipoDocumento,
        numeroDocumento: values.numeroDocumento,
      },
    };
    axios
      .post("/auth/signup", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          resetValues();
          openSuccessToast(
            "Se ha creado el usuario " + values.nombreUsuario + " correctamente"
          );
        }
      })
      .catch((error) => {
        errorHandler(error, "Error al crear el usuario");
      });
  };
  return (
    <div className="main-container-register-form">
      <Title text="Registrar usuario" />
      <ButtonHome />
      <div className="main_cond">
        <div className="form_group">
          <input
            type="text"
            className="form_input"
            name="nombreUsuario"
            value={values.nombreConductor}
            placeholder=" "
            onChange={handleInputChange}
            id="nombreUsuario"
          />
          <label for="nombreUsuario" className="form_label">
            Nombre completo
          </label>
        </div>
        <div className="form_group">
          <input
            type="text"
            className="form_input"
            name="tipoDocumento"
            value={values.tipoDocumento}
            placeholder=" "
            onChange={handleInputChange}
            id="tipoDocumento"
          />
          <label for="tipoDocumento" className="form_label">
            Tipo de documento
          </label>
        </div>
        <div className="form_group">
          <input
            type="text"
            className="form_input"
            name="numeroDocumento"
            value={values.numeroDocumento}
            placeholder=" "
            onChange={handleInputChange}
            id="numeroDocumento"
          />
          <label for="numeroDocumento" className="form_label">
            Número de documento
          </label>
        </div>
        <div className="form-group-select-cc">
          <select
            className="form-input-select"
            placeholder=" "
            onChange={(e) => {
              setValues({
                ...values,
                ["rol"]: e.target.value,
              });
            }}
            value={values.rol}
          >
            <option key="administrador" value="ROLE_ADMIN">
              ADMINISTRADOR
            </option>
            <option key="revisor" value="ROLE_REVISOR">
              REVISOR
            </option>
            <option key="interno" value="ROLE_CONDUCTOR_INTERNO">
              CONDUCTOR INTERNO
            </option>
            <option value="" selected disabled hidden>
              Seleccione el rol
            </option>
          </select>
          <label htmlFor="rol" className="form-label-select">
            Rol
          </label>
        </div>

        <button type="submit" className="btn_submit" onClick={sendData}>
          Enviar datos
        </button>
      </div>
    </div>
  );
}
