import React from "react";
import Title from "../../components/Title/Title";
import "./EditConductorPage.css";
import FormConductor from "../../components/FormConductor/FormConductor";
import ButtonHome from "../../components/ButtonHome/ButtonHome";

export default function EditConductorPage() {
    const user = JSON.parse(localStorage.getItem("user"));
    const conductor = user.empleado;
  return (
    <div className="main-container-edit-conductor">
      <Title text="Editar información" />
      <ButtonHome />
      <div className="container-edit-conductor">
        <FormConductor conductor={conductor}/>
      </div>
    </div>
  );
}
    
