import React, { useEffect, useState } from "react";
import Title from "../../components/Title/Title";
import AutocompletePaciente from "../../components/AutocompletePaciente/AutocompletePaciente";
import IconButtonTable from "../../components/IconButtonTable/IconButtonTable";
import ModalValueCuenta from "../../components/Modal/ModalValueCuenta";
import { FaFilter, FaEdit, FaRegFilePdf, FaTrashAlt } from "react-icons/fa";
import { LuFileOutput } from "react-icons/lu";
import { FcOk, FcCancel } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import "./ViewCuentasCobroPage.css";
import axios from "../../utils/axios";
import { errorHandler, openSuccessToast } from "../../utils/errorToast";
import ButtonHome from "../../components/ButtonHome/ButtonHome";
import ViewCuentasCobroTable from "../../components/Table/ViewCuentasCobroTable";
import {
  useFilters,
  useGlobalFilter,
  useTable,
  usePagination,
} from "react-table";
import moment from "moment";
import { ColumnFilter } from "../../components/Table/ColumnFilter";
import Loading from "../../components/Loading/Loading";
import Pagination from "../../components/Table/Pagination";
import ModalConfirmation from "../../components/Modal/ModalConfirmation";
export default function ViewCuentasCobroPage() {
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.roles.includes("ROLE_ADMIN");
  const isRevisor = user?.roles.includes("ROLE_REVISOR");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cuentaToEdit, setCuentaToEdit] = useState(null);
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [dataMdalConfirmation, setDataMdalConfirmation] = useState(null);
  const [modalAction, setModalAction] = useState({ action: "", text: "" });
  const [pageState, setPageState] = useState({});
  const columns = React.useMemo(
    () => [
      {
        Header: "Descripción",
        accessor: "mesCuenta",
        // width: "12.5%",
        minWidth: "120px",
        width: "140px",
      },
      {
        Header: "Fecha",
        accessor: "fechaCuentaFormatted",
        minWidth: "70px",
        width: "70px",
      },
      {
        Header: "Numero documento",
        accessor: "conductor.numeroDocumento",
        minWidth: "130px",
      },
      {
        Header: "Conductor",
        accessor: "conductor.nombreEmpleado",
        minWidth: "130px",
      },
      {
        Header: "Estado",
        accessor: "estado",
        minWidth: "70px",
        width: "70px",
      },
      {
        Header: "Valor total",
        accessor: "valorTotal",
        minWidth: "130px",
        Cell: ({ row }) => (
          <>
            {user?.roles.includes("ROLE_ADMIN") ? (
              <button
                type="submit"
                className="btn-valor-total"
                onClick={() => {
                  setCuentaToEdit(row.original);
                  setIsModalOpen(true);
                }}
              >
                {"$ " +
                  row.original.valorTotal.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })}
              </button>
            ) : (
              "$ " +
              row.original.valorTotal.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              })
            )}
          </>
        ),
      },
      {
        Header: "Observaciones",
        accessor: "observaciones",
        minWidth: "130px",
        Cell: ({ row }) => (
          <>
            {row.original.observaciones && (
              <button
                type="submit"
                className="btn-valor-total"
                onClick={() => {
                  setCuentaToEdit(row.original);
                  setIsModalOpen(true);
                }}
              >
                Ver observaciones
              </button>
            )}
          </>
        ),
      },
      {
        Header: "Acciones",
        minWidth: "130px",
        Cell: ({ row }) => (
          <>
            {isAdmin && (
              <IconButtonTable
                title="Aprobar  cuenta de cobro"
                icon={<FcOk size={25} color="#ff8144" />}
                action={() => approveCuentaCobro(row.original, "Aprobada")}
              />
            )}
            {isAdmin && (
              <IconButtonTable
                title="Rechazar cuenta de cobro"
                icon={<FcCancel size={25} color="#ff8144" />}
                action={() => rejectCuenta(row.original, "Rechazada")}
              />
            )}
            {isAdmin && (
              <IconButtonTable
                title="Devolver cuenta de cobro"
                icon={<LuFileOutput size={25} color="#ff8144" />}
                action={() => handleReturnCuenta(row.original, "Rechazada")}
              />
            )}
            <IconButtonTable
              title="Ver cuenta de cobro"
              icon={<FaEdit size={25} color="#ff8144" />}
              action={() => handleEdit(row.original)}
            />
            <IconButtonTable
              title="Descargar cuenta de cobro"
              icon={<FaRegFilePdf size={25} color="#ff8144" />}
              action={() => handleDownload(row.original)}
            />
            {(user?.roles.includes("ROLE_CONDUCTOR") ||
              user?.roles.includes("ROLE_CONDUCTOR_INTERNO")) && (
              <IconButtonTable
                title="Borrar cuenta de cobro"
                icon={
                  <FaTrashAlt
                    size={25}
                    color={
                      row.original.estado === "Borrador" ? "#ff8144" : "#91918e"
                    }
                  />
                }
                action={() => handleDeleteCuenta(row.original)}
                disabled={row.original.estado !== "Borrador"}
              />
            )}
          </>
        ),
      },
    ],
    []
  );

  const navigate = useNavigate();
  const [cuentasCobro, setCuentasCobro] = useState([]);
  const [filterPaciente, setFilterPaciente] = useState("");
  const [valorTotal, setValorTotal] = useState(0);
  const [pacientes, setPacientes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    reloadCuentas();
  }, [setCuentasCobro]);

  useEffect(() => {
    if (isAdmin || isRevisor) {
      axios
        .get("getAllPacientes")
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            setPacientes(res.data);
          }
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  }, [setPacientes]);

  const reloadCuentas = () => {
    setIsLoading(true);
    axios
      .get("getAllCuentasCobro")
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          // copy obj to create formatted datetime
          const newData = res.data.map((item) => {
            const momentObject = moment(item.fechaCuenta);
            item.fechaCuentaFormatted = momentObject.format("LLL");
            return item;
          });
          setCuentasCobro(newData);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error, "Error al cargar las cuentas de cobro");
      });
  };

  const handleEdit = (data) => {
    navigate("/cuenta", { state: { data: data } });
  };
  const handleDownload = (data) => {
    setIsLoading(true);
    axios
      .get("getCuentaCobroReporte", {
        params: {
          cuentaCobroId: data.cuentaCobroId,
          nombreConductor: data.conductor.nombreEmpleado,
          cedulaConductor: data.conductor.numeroDocumento,
        },
        responseType: "blob",
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
          //Create a Blob from the PDF Stream
          const file = new Blob([res.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
          // const href = URL.createObjectURL(res.data);

          // // create "a" HTML element with href to file & click
          // const link = document.createElement("a");
          // link.href = href;
          // link.setAttribute("target","_blank")
          // link.setAttribute("type","pdf")
          // // link.setAttribute("download", "cuenta_cobro.pdf"); //or any other extension

          // document.body.appendChild(link);
          // link.click();

          // // clean up "a" element & remove ObjectURL
          // document.body.removeChild(link);
          // URL.revokeObjectURL(href);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error, "Error al descargar la cuentas de cobro");
      });
  };
  const handleDeleteCuenta = (data) => {
    setModalAction({
      action: "DELETE",
      text: "¿Está seguro/a de borrar la cuenta de cobro?",
    });
    setDataMdalConfirmation(data);
    setIsModalConfirmationOpen(true);
  };
  const handleReturnCuenta = (data) => {
    setModalAction({
      action: "RETURN",
      text: "¿Está seguro/a de devolver la cuenta de cobro?",
    });
    setDataMdalConfirmation(data);
    setIsModalConfirmationOpen(true);
  };
  const onClickAcceptModal = () => {
    if (modalAction.action === "DELETE") {
      deleteCuentaCobro(dataMdalConfirmation);
    } else if (modalAction.action === "RETURN") {
      returnCuentaCobro(dataMdalConfirmation, "Rechazada");
    }
  };
  const deleteCuentaCobro = (data) => {
    setIsLoading(true);
    axios
      .post("deleteCuentaCobro", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
          reloadCuentas();
          openSuccessToast("Se ha borrado la cuenta de cobro");
          setDataMdalConfirmation(null);
        }
      })
      .catch((error) => {
        setDataMdalConfirmation(null);
        setIsLoading(false);
        let message = error.response?.data?.message
          ? error.response?.data?.message
          : "Error al borrar la cuenta de cobro";
        errorHandler(error, message);
      });
  };
  const filterByPaciente = () => {
    setIsLoading(true);
    axios
      .post("getCuentasCobroByPaciente", filterPaciente)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
          setCuentasCobro(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error, "Error al buscar el paciente");
      });
  };
  const rejectCuenta = (cuenta, newEstado) => {
    cuenta = { ...cuenta, ["estado"]: newEstado };
    setCuentaToEdit(cuenta);
    setIsModalOpen(true);
  };

  const approveCuentaCobro = (cuenta, newEstado) => {
    cuenta = { ...cuenta, ["estado"]: newEstado };
    axios
      .post("approveCuentaCobro", cuenta)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          openSuccessToast("Se ha aprobado la cuenta de cobro");
          reloadCuentas();
        }
      })
      .catch((error) => {
        errorHandler(error, "Error al aprobar cuenta de cobro");
      });
  };

  const returnCuentaCobro = (cuenta, newEstado) => {
    cuenta = { ...cuenta, ["estado"]: newEstado };
    axios
      .post("returnCuentaCobro", cuenta)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          openSuccessToast("Se ha devuelto la cuenta de cobro");
          reloadCuentas();
        }
      })
      .catch((error) => {
        errorHandler(error, "Error al devolver cuenta de cobro");
      });
  };
  const getSelectedVal = (value) => {
    const found = pacientes.find((p) => p.nombrePaciente === value);
    setFilterPaciente(found);
  };

  const getChanges = () => {
    setFilterPaciente({});
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data: cuentasCobro,
      defaultColumn: { Filter: ColumnFilter },
      initialState: {
        ...pageState,
        hiddenColumns: user?.roles.includes("ROLE_CONDUCTOR_INTERNO")
          ? ["valorTotal"]
          : [],
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  // useEffect para calcular el valor todas de las filas que se muestran
  useEffect(() => {
    let total = 0;
    for (let i = 0; i < rows.length; i++) {
      let cc = rows[i].original;
      if (cc.estado === "Aprobada") {
        total += cc.valorTotal;
      }
    }
    setValorTotal(total);
  }, [cuentasCobro, rows]);
  const getCuentasCsv = () => {
    setIsLoading(true);
    const actualRows = rows.map((row) => row.original);
    axios
      .post("generarCSV", actualRows)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "cuentas_cobro.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error, "Error al cargar las cuentas de cobro");
      });
  };
  return (
    <div className="container-cuentas">
      <Title text="Cuentas de cobro" />
      <ButtonHome />
      {(user?.roles.includes("ROLE_ADMIN") ||
        user?.roles.includes("ROLE_REVISOR")) && (
        <div className="container-filter-cuentas">
          <div className="filter-cuentas-comps">
            <AutocompletePaciente
              label="Nombre del paciente"
              pholder="Nombre del paciente..."
              data={pacientes}
              onSelected={getSelectedVal}
              onChange={getChanges}
              filterByName={true}
            />
            <IconButtonTable
              title="Buscar paciente"
              icon={<FaFilter size={25} color="#ff8144" />}
              action={filterByPaciente}
            />
          </div>
          <button
            type="submit"
            className="btn-load-cuentas"
            onClick={getCuentasCsv}
          >
            Descargar
          </button>
        </div>
      )}

      <div className="container-view-table-cuenta">
        <section>
          <ViewCuentasCobroTable
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={page}
            prepareRow={prepareRow}
            allRows={rows}
          />
        </section>
      </div>
      <Pagination
        gotoPage={gotoPage}
        length={rows.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      {!user?.roles.includes("ROLE_CONDUCTOR_INTERNO") && (
        <h1>
          {"Valor total: $" +
            valorTotal.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })}
        </h1>
      )}

      {isModalOpen && (
        <ModalValueCuenta
          setIsOpen={setIsModalOpen}
          reloadCuentas={reloadCuentas}
          cuenta={cuentaToEdit}
        />
      )}
      {isLoading && <Loading />}
      {isModalConfirmationOpen && (
        <ModalConfirmation
          setIsOpen={setIsModalConfirmationOpen}
          text={modalAction.text}
          onClickAccept={onClickAcceptModal}
        />
      )}
    </div>
  );
}
