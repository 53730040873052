import React from "react";
import "./Table.css";
import { useEffect } from "react";

export default function ViewCuentasCobroTable({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  allRows,
  prepareRow,
}) {
  const user = JSON.parse(localStorage.getItem("user"));

  const getMonthYear = (date) => {
    const d = new Date(date);
    return `${d.getMonth() + 1}-${d.getFullYear()}`;
  };

  // Define rows color
  useEffect(() => {
    if (user?.roles.includes("ROLE_ADMIN")) {
      const seenIdMonth = {};

      allRows.forEach((row, index) => {
        const empleadoId = row.original.conductor.empleadoId;

        const monthYear = getMonthYear(row.original.fechaCuenta);
        const key = `${empleadoId}-${monthYear}`;

        if (!seenIdMonth[key]) {
          seenIdMonth[key] = [];
        }
        // Add index of cuenta de cobro row sent by the same driver within the same month
        seenIdMonth[key].push(index);
      });

      for (const key in seenIdMonth) {
        const indexes = seenIdMonth[key];

        for (let i = 0; i < indexes.length - 1; i++) {
          const index = indexes[i];
          // Change row color for all cuentas de cobro sent within the same month by the same driver
          allRows[index].original.bgColor = "#e7e3e3";
        }
        // Define which rows indexes are white
        const lastIndex = indexes[indexes.length - 1];
        allRows[lastIndex].original.bgColor = "white";
      }
    }
  }, [allRows, user]);

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps({
                  style: { minWidth: column.minWidth, width: column.width },
                })}
              >
                {column.render("Header")}
                <div>{column.canFilter ? column.render("Filter") : null}</div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <tr key={rowIndex} {...row.getRowProps()}>
              {row.cells.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  {...cell.getCellProps({
                    style: {
                      minWidth: cell.column.minWidth,
                      width: cell.column.width,
                      backgroundColor: row.original.bgColor ?? "white",
                    },
                  })}
                >
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
