import React, { useEffect, useState } from "react";
import Title from "../../components/Title/Title";
import "./ConductoresPage.css";
import Table from "../../components/Table/Table";
import { FaEdit, FaFileInvoiceDollar, FaFileInvoice } from "react-icons/fa";
import { MdLockReset } from "react-icons/md";
import axios from "../../utils/axios";
import IconButtonTable from "../../components/IconButtonTable/IconButtonTable";
import ModalConductores from "../../components/Modal/ModalConductores";
import {
  errorHandler,
  openErrorToast,
  openSuccessToast,
} from "../../utils/errorToast";
import ButtonHome from "../../components/ButtonHome/ButtonHome";
import Loading from "../../components/Loading/Loading";

export default function ConductoresPage() {
  const [conductores, setConductores] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [conductorToEdit, setConductorToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.roles.includes("ROLE_ADMIN");

  const columns = [
    {
      Header: "Tipo de documento",
      accessor: "tipoDocumento",
    },
    {
      Header: "Número documento",
      accessor: "numeroDocumento",
    },
    {
      Header: "Nombre",
      accessor: "nombreEmpleado",
    },
    {
      Header: "Dirección",
      accessor: "dirEmpleado",
    },
    {
      Header: "Celular",
      accessor: "celEmpleado",
    },
    {
      Header: "Ciudad",
      accessor: "ciudad",
    },
    {
      Header: "Correo electrónico",
      accessor: "correoElectronico",
    },
    {
      Header: "Placa",
      accessor: "placa",
    },
    {
      Header: "Número de cuenta",
      accessor: "numeroCuenta",
    },
    {
      Header: "Tipo de cuenta",
      accessor: "tipoCuenta",
    },
    {
      Header: "Banco",
      accessor: "banco",
    },
    {
      Header: "Zonas",
      accessor: "zonas",
    },
    {
      Header: "Peajes",
      accessor: "tienePeajes",
      Cell: ({ row }) => (
        <>{row.original.tienePeajes ? "Habilitado" : "Deshabilitado"}</>
      ),
    },
    {
      Header: "Impulsos",
      accessor: "tieneImpulsos",
      Cell: ({ row }) => {
        return (
          <>{row.original.tieneImpulsos ? "Habilitado" : "Deshabilitado"}</>
        );
      },
    },
    {
      Header: "Rol",
      accessor: "rol",
      Cell: ({ row }) => {
        return (
          <>{row.original.rol === "ROLE_CONDUCTOR_INTERNO" ? "CONDUCTOR INTERNO" : "CONDUCTOR"}</>
        );
      },
    },
    {
      Header: "Acciones",
      Cell: ({ row }) => (
        <>
          <IconButtonTable
            title="Editar conductor"
            icon={<FaEdit size={25} color="#ff8144" />}
            action={() => handleEditConductor(row.original)}
          />
          <IconButtonTable
            title="Habilitar peajes"
            icon={<FaFileInvoiceDollar size={25} color="#ff8144" />}
            action={() => handleEnablePeajes(row.original)}
          />
          <IconButtonTable
            title="Habilitar Impulsos"
            icon={<FaFileInvoice size={25} color="#ff8144" />}
            action={() => handleEnableImpulsos(row.original)}
          />
          <IconButtonTable
            title="Restablecer contraseña"
            icon={<MdLockReset size={25} color="#ff8144" />}
            action={() => handleResetPassword(row.original)}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    getAllConductores();
  }, [setConductores]);
  const getAllConductores = () => {
    setIsLoading(true);
    axios
      .get("getAllConductores")
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
          setConductores(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error, "Error al cargar los conductores");
      });
  };
  const handleEditConductor = (data) => {
    setConductorToEdit(data);
    setIsModalOpen(true);
  };
  const handleResetPassword = (data) => {
    setIsLoading(true);
    axios
      .post("auth/resetPassword", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          openSuccessToast("Se restableció la contraseña del conductor");
          setIsLoading(false);
          getAllConductores();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error, "Error al restablecer la contraseña");
      });
  };

  const handleEnableImpulsos = (data) => {
    data = {
      ...data,
      ["tieneImpulsos"]: !data.tieneImpulsos,
    };
    setIsLoading(true);
    axios
      .post("enableImpulsos", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          data.tieneImpulsos
            ? openSuccessToast("Se habilitaron los impulsos al conductor")
            : openSuccessToast("Se deshabilitaron los impulsos al conductor");
          setIsLoading(false);
          getAllConductores();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error, "Error al habilitar los impulsos al conductor");
      });
  };

  const handleEnablePeajes = (data) => {
    data = {
      ...data,
      ["tienePeajes"]: !data.tienePeajes,
    };
    setIsLoading(true);
    axios
      .post("enablePeajes", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          data.tienePeajes
            ? openSuccessToast("Se habilitaron los peajes al conductor")
            : openSuccessToast("Se deshabilitaron los peajes al conductor");
          setIsLoading(false);
          getAllConductores();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error, "Error al habilitar los peajes al conductor");
      });
  };
  const downloadConductores = () => {
    setIsLoading(true);
    const actualRows = conductores.map((row) => row.original);
    axios
      .post("downloadConductores", conductores)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "conductores.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error, "Error al descargar las rutas");
      });
  };

  const uploadPacientes = (event) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    axios
      .post("uploadEmpleados", formData)
      .then(() => {
        setIsLoading(false);
        getAllConductores();
        openSuccessToast("Se han cargado los conductores");
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error);
      });
  };
  return (
    <div className="container-conductores">
      <Title text="Titulares de las cuentas de cobro" />
      <ButtonHome />
      <div className="container-buttons-conductores">
        <button
          type="submit"
          className="btn-add-conductor"
          onClick={() => {
            setConductorToEdit(null);
            setIsModalOpen(true);
          }}
        >
          Agregar titular
        </button>
        <label for="file" className="btn-add-conductor">
          Cargar titulares
        </label>
        <input type="file" id="file" onChange={(e) => uploadPacientes(e)} />

        {isAdmin && (
          <button
            type="submit"
            className="btn-load-cuentas"
            onClick={downloadConductores}
          >
            Descargar titulares
          </button>
        )}
      </div>
      <div className="container-conductores-table">
        <section>
          <Table columns={columns} data={conductores} />
        </section>
      </div>
      {isModalOpen && (
        <ModalConductores
          setIsOpen={setIsModalOpen}
          reloadConductores={getAllConductores}
          conductor={conductorToEdit}
        />
      )}
      {isLoading && <Loading />}
    </div>
  );
}
