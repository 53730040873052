import React from "react";
import "./CuentaCobroTable.css";
import Table from "../Table/Table";
import IconButtonTable from "../IconButtonTable/IconButtonTable";
import { FaTrashAlt } from "react-icons/fa";
import { AiFillCaretRight, AiFillCaretDown } from "react-icons/ai";

export default function CuentaCobroTable({
  data,
  onClickSave,
  onClickSend,
  removeRow,
  estado,
}) {
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.roles.includes("ROLE_ADMIN");
  estado === "Borrador" ||
    estado === null ||
    user?.roles.includes("ROLE_ADMIN");
  const editable = estado === "Borrador" || estado === null || isAdmin;
  const sortBy = [
    {
      id: "paciente.nombrePaciente",
      desc: false,
    },
    {
      id: "fecha",
      desc: false,
    },
  ];

  const columns = [
    {
      // Make an expander cell
      Header: () => null, // No header
      id: "expander", // It needs an ID
      width: "5px",
      Cell: ({ row }) => (
        <>
          {row.original.ruta.tipoRuta === "SENCILLO" && (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <AiFillCaretDown size={20} color="#ff8144" />
              ) : (
                <AiFillCaretRight size={20} color="#ff8144" />
              )}
            </span>
          )}
        </>
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter
        // to build the expander.
      ),
    },
    {
      Header: "Fecha",
      accessor: "fecha",
      // width: "12.5%",
    },
    {
      Header: "Paciente",
      accessor: "paciente.nombrePaciente",
      sortbyOrder: "asc",
      // width: "12.5%",
    },
    {
      Header: "Eps",
      accessor: "paciente.eps",
      // width: "12.5%",
    },
    {
      Header: "Origen",
      accessor: "ruta.ciudadOrigen.nombreCiudad",
      // width: "12.5%",
    },
    {
      Header: "Destino",
      accessor: "ruta.ciudadDestino.nombreCiudad",
      // width: "12.5%",
    },
    {
      Header: "Tipo de recorrido",
      accessor: "ruta.tipoRuta",
      // width: "12.5%",
    },
    {
      Header: "Valor a pagar",
      accessor: "valorPago",
      // width: "12.5%",
      Cell: ({ row }) => (
        <>
          $
          {row.original.esAdicional
            ? row.original?.valorAdicional?.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              })
            : row.original?.precioActual?.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              })}
        </>
      ),
    },
    {
      Header: "Acciones",
      width: "70px",
      Cell: ({ row }) => (
        <>
          <div className="container-action-buttons-table">
            <IconButtonTable
              title="Borrar registro"
              icon={
                <FaTrashAlt
                  size={25}
                  color={editable ? "#ff8144" : "#91918e"}
                />
              }
              action={() => removeRow(row.original)}
              disabled={!editable}
            />
          </div>
        </>
      ),
    },
  ];

  const hiddenColumns = ["valorPago"];

  return (
    <div className="container-table-cuenta">
      {data.length > 0 ? (
        <section className="container-table-cuenta-comps">
          <Table
            columns={columns}
            data={data}
            sortByDefault={sortBy}
            hideColumns={
              user?.roles.includes("ROLE_CONDUCTOR_INTERNO")
                ? hiddenColumns
                : []
            }
          />
          {editable && (
            <div className="container-buttons-table-cuenta">
              <button
                type="submit"
                className="btn-save-cuenta"
                onClick={() => {
                  onClickSave("Borrador");
                }}
              >
                Guardar cuenta
              </button>
              {!isAdmin && (
                <button
                  type="submit"
                  className="btn-save-cuenta"
                  onClick={() => {
                    onClickSend();
                  }}
                >
                  Enviar cuenta
                </button>
              )}
            </div>
          )}
        </section>
      ) : null}
    </div>
  );
}
